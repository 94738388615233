.about-partners-desktop {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20px;
}

.about-partners-tablet,
.about-partners-mobile {
	display: flex;
	flex-direction: row;
	overflow: auto;
	flex-wrap: nowrap;
}