@import "src/assets/sass/variables.scss";

.profile-card-list-container {
	display: flex;
	overflow-x: scroll;
	height: 120px;
	scrollbar-color: white transparent;
	border-width: 2px;
	border-style: solid;
	border-radius: 20px;
	border-color: $color-black;
}
