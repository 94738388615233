@import "src/assets/sass/variables.scss";
@import "src/assets/sass/mixins.scss";

footer {
	@include headerFooter();
	
	font-family: 'Slalom Sans';
	position: relative;
	width: 100%;
	height: 80px;
	color: $color-black;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: default;
	backdrop-filter: blur(40px);
}

.footer-text-container {
	width: 280px;
	margin: auto;
	font-size: 16px;
	font-weight: 400;
}

.footer-text-container-2 {
	@include headerFooter();

	display: flex;
	align-items: center;
	margin: auto;
	padding-bottom: 20px;
	font-size: 16px;
	font-weight: 400;
}

.footer-text-container a {
	color: $color-purple;
	font-weight: 400;
}

.footer-tablet {
	height: 142px;
	flex-direction: column;
	padding: 26.5px 16px;
}

.footer-tablet .footer-text-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.footer-mobile {
	height: 209px;
	padding: 32px 16px;
}

.footer-mobile .footer-text-container {
	flex-direction: column;
	width: 280px;
	margin-bottom: 16px;
	font-size: 14px;
}