@import 'src/assets/sass/variables.scss';

.wrap-page {
	// background-image: url('../../assets/images/wrap-page-bg-desktop.jpg');
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// padding-top: 80px;
}

.wrap-page.tablet {
	margin-bottom: -140px;
}

.wrap-page.mobile {
	justify-content: flex-start;
}

.wrap-page-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	// color: $color-white;
	// background: rgba(21, 29, 46, 0.8);
	// border-radius: 16px;
}

.wrapup-page-container-tablet {
	margin-right: 24px;
	margin-left: 24px;
}

.wrapup-page-container-mobile {
	border-radius: 0px;
	height: 100%;
	justify-content: space-around;
}

.wrapup-title {
	font-family: Slalom Sans;
	font-weight: 700;
	font-style: normal;
	font-size: 16;
	line-height: 20;
	margin: 50px 0px;
	padding: 0px 15px;
}

.wrapup-header-desktop,
.wrapup-header-tablet {
	font-family: Lora;
	font-weight: 700;
	font-style: normal;
	font-size: 38px;
	line-height: 45px;
	margin-bottom: 24px;
	max-width: 770px;
	text-align: center;
}

.wrapup-header-mobile {
	text-align: center;
	font-family: Lora;
	font-weight: 700;
	font-style: normal;
	font-size: 38px;
	line-height: 45px;
	margin-bottom: 32px;
	padding: 0 36px;
}

.wrapup-content-desktop,
.wrapup-content-tablet {
	font-family: Slalom Sans;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 20px;
	padding: 0 35px;
	margin: 0 auto;
	margin-bottom: 36px;
	justify-content: center;
	max-width: 770px;
	text-align: center;
}

.wrapup-content-mobile {
	font-family: Slalom Sans;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 20px;
	padding: 0 36px;
	margin-bottom: 32px;
	text-align: left;
}

.wrapup-button-container-desktop,
.wrapup-button-container-tablet {
	display: flex;
	flex-direction: column;
	align-self: center;
}

.wrap-page-container.mobile {
	display: flex;
	flex-direction: column;
}

.wrap-page-header {
	font-weight: 700;
	text-align: center;
	color: #ffffff;
	font-size: 38px;
	line-height: 46px;
	max-width: 580px;
	margin: 0px 0px 32px 0px;
	padding: 0px 10px 0px 0px;
}

.wrap-page-header.mobile {
	margin: 48px 0px 32px 0px;
	max-width: 580px;
	padding-top: 150px;
	font-size: 32px;
	line-height: 38px;
}

.wrap-page-text {
	margin: 0px 0px 48px 0px;
	font-size: 16px;
	line-height: 19px;
	color: #ffffff;
}

.wrap-page-text.desktop {
	padding: 0px 10px 0px 10px;
}

.wrap-button {
	width: 100%;
	margin: 12px 0;
	align-items: center;
	justify-content: center;
}

.wrap-button-flex {
	display: flex;
	gap: 20px;
}

.wrap-button-flex.mobile {
	flex-direction: column;
	align-items: center;
}

.top-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	justify-self: center;
	gap: 20px;
	margin-bottom: 36px;
	margin-right: 24px;
	margin-left: 24px;
	flex-wrap: wrap;
}

.top-buttons-tablet {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
	max-width: 60%;
	margin: auto;
	margin-bottom: 36px;
}

.wrap-page-bg-container {
	// background-color: #cecece;
	// height: 100%;
	padding: 60px 0;
	background-image: url('../../assets/images/bg-desktop.jpg');
	background-size: cover;
	background-repeat: no-repeat;
}

@media screen and (max-width: 968px) {
	.wrap-page-container {
		padding: 0px 20px;
	}
}
