@import "src/assets/sass/variables.scss";

.sponsor-partner-desktop-component {
	width: 428px;
	height: 200px;
	border-radius: 20px;
	margin-bottom: 18px;
	margin-right: 16px;
	display: flex;
	cursor: pointer;
	background-color: white;
	border: 2px solid $color-black;
	overflow: hidden;
	justify-content: center;
	align-items: center;
}

.sponsor-tile-container {
	width: 419px;
	height: 425px;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	margin-bottom: 16px;
}

.sponsor-tile-image-container {
	color: FFFFFF;
	width: 100%;
	height: 200px;
	// background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border: 2px solid $color-black;
	border-width: 2px 2px 0 2px; 
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	box-shadow: 8px 8px 0px $color-light-purple;
	cursor: pointer;
}

.sponsor-tile-info-container {
	width: 100%;
	padding: 0 8px;
	height: 235px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	text-align: center;
	background: linear-gradient(0deg, #EEE9FF, #EEE9FF);
	backdrop-filter: blur(21px);
	border: 2px solid $color-black;
	border-width: 2px 2px 2px 2px; 
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	box-shadow: 8px 8px 0px $color-light-purple;
}

.sponsor-name-container {
	font-family: "Lora";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: $color-black;
	margin-top: 20px;
}

.sponsor-blurb-container {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400, regular;
	color: $color-black;
	margin-top: 10px;
	margin-bottom: 20px;
}

@media screen and (max-width: 1182px) {
	.sponsor-tile-margin {
		margin-right: 16px;
	}
}