@import "src/assets/sass/variables.scss";

.errorpage-video-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: #cccccc;
	z-index: -1;
}

.errorpage-background-video {
	z-index: -1;
	object-fit: cover;
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
}

.errorpage-background-image {
	z-index: -1;
	object-fit: cover;
	width: 100vw;
	height: calc(100vh + 24px);
	position: fixed;
	top: -12px;
	left: 0;
}


.error-btn-container-desktop {
	margin: 0 0px 100px 0;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-direction: row;
	position: fixed;
	bottom: 5px;
	left: 60px;
}

.error-btn-container-tablet {
	margin: 0 10px 85px 0;
	display: flex;
	justify-content: center;
}

.error-btn-container-mobile {
	margin: 0 0 60px 0;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-position: 100%;
}

.error-btn-text-only {
	background: none;
	border: none;
	box-shadow: none;
	filter: none;
}

.app-button-secondary {
	color: $color-black;
}


.app-button-secondary:visited {
	background: none;
	box-shadow: none;
}

.app-button-secondary:hover {
	background: none;
	box-shadow: none;
	background-color: none;
}

.error-btn-text-only:hover {
	background: none;
	border-radius: 50px;
	box-shadow: none;
	filter: none;
	color: #9B9B9B;
}

.error-btn-text-only:active {
	background: none;
	border-radius: 50px;
	box-shadow: none;
	filter: none;
	color: $color-black;

}

.btn-label {
	/* Contact Team button */

	font-family: 'Lora';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	display: flex;
	align-items: center;
	text-align: center;
	padding: 14px 20px;
	
}

.btn-label-primary {
	/* Return home button */

	font-family: 'Lora';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	border: 2px solid #C7B9FF;
	border-radius: 50px;
	padding: 14px 20px;
	gap: 10px;
	text-align: center;
	color: $color-black;
	background: linear-gradient(0deg, #EEE9FF, #EEE9FF);

}

.btn-label-primary:hover {
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 20px;
	gap: 10px;

	background-color: none;
	background: none;
	border: 2px solid #C7B9FF;
	border-radius: 50px;
	color: $color-black;
}

.btn-label-primary:active {
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 20px;
	gap: 10px;

	background: #C7B9FF;
	border: 2px solid #C7B9FF;
	border-radius: 50px;
	color: $color-black;
}

.error-page-container-large {
	margin: 0 60px;
	display: flex;
	flex-direction: column;
}

.error-page-container-mobile {
	margin: 0 40px;
	display: flex;
	flex-direction: column;
}
