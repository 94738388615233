@import 'src/assets/sass/variables.scss';

.app-button-primary {
	align-items: center;
	justify-content: center;
	background: $color-lilac;
	border: 2px solid $color-light-purple;
	border-radius: 50px;
	color: $color-black;
	display: flex;
	padding: 14px 20px;
	font-size: 16px;
}

.app-button-primary:hover {
	background-color: $color-white;
	color: $color-black;
}

.app-button:hover {
	cursor: pointer;
	background-color: none;
}

.app-button-about-contact {
	margin-top: 24px;
}
