@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.top-leader-card {
	font-family: 'Slalom Sans';
	display: flex;
	position: relative;
	border-radius: 20px;
	@include leaderboardCard();
	color: $color-black;
	max-width: 31%;
}

.top-leader-content {
	border-radius: 20px 10px 10px 20px;
	padding: 10px 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 32px;
	min-height: 260px;
	z-index: 1;
}

.position-image {
	position: absolute;
	background-repeat: no-repeat;
	top: 11px;
	right: 0;
	z-index: 0;
	width: 250px;
	height: 250px;
}

.top-leader-avatar {
	width: 50px;
	height: 50px;
	border-radius: 50%;
}

.top-leader-stat-h2 {
	margin: 0;
	font-family: Lora;
	font-size: 24px;
	font-weight: 700;
}

.top-leader-stat-h3 {
	margin: 0;
	font-size: 16px;
	font-weight: 400;
}

.top-leader-stat-h4 {
	margin: 0;
	font-size: 16px;
	font-weight: 700;
}

.position-0 {
	background-image: url('../../assets/images/leaderboard-gold.png');
}

.position-1 {
	background-image: url('../../assets/images/leaderboard-silver.png');
}

.position-2 {
	background-image: url('../../assets/images/leaderboard-bronze.png');
}

.position-3 {
	background-image: url('../../assets/images/leaderboard-gold.png');
}

.position-4 {
	background-image: url('../../assets/images/leaderboard-silver.png');
}

@media screen and (min-width: 1388px) {
	.top-leader-card {
		height: 260px;
		width: 31%;
		max-width: 400px;
	}

	.top-leader-content {
		width: 75%;
	}
}

@media screen and (min-width: 981px) {
	.top-leader-card {
		width: 32%;
	}
}

@media screen and (max-width: 980px) {
	.top-leader-card {
		flex-direction: column;
		width: 31%;
		max-width: 225px;
		min-height: 301px;
		border-radius: 16px;
		overflow: hidden;
		justify-items: center;
	}

	.top-leader-content {
		border-radius: 16px 16px 8px 8px;
		align-items: center;
		padding-top: 40px;
	}

	.top-leader-rank {
		width: 40px;
		height: 40px;
		right: 10px;
		top: 10px;
	}

	.top-leader-stat-h2 {
		font-family: Lora;
		font-size: 18px;
	}

	.position-image {
		position: absolute;
		background-repeat: no-repeat;
		background-position: right;

		margin-top: 140px;
		right: 0;
		bottom: 0px;
		width: 100%;
		width: 150px;
		height: 150px;
	}

	.position-0 {
		background-image: url('../../assets/images/leaderboard-gold-sm.png');
	}

	.position-1 {
		background-image: url('../../assets/images/leaderboard-silver-sm.png');
	}

	.position-2 {
		background-image: url('../../assets/images/leaderboard-bronze-sm.png');
	}
}

@media screen and (max-width: 705px) {
	.top-leader-card {
		max-width: 300px;
		min-width: 200px;
		margin-right: 16px;

		&:last-child {
			margin-right: 0;
		}
	}
}
