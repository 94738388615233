$color-slalom-primary: rgba(222, 255, 77, 1);
$color-bg-light: #ebebeb;
$color-bg-border: #9c9c9c;

$color-black: #000;
$color-purple: #7475ad;
$color-dark-purple: #1b2b5a;
$color-light-purple: #c7b9ff;
$color-dark-purple: #7475AD;
$color-lilac: #EEE9FF;
$color-white: #fff;
$color-gray: #b4b4b4;
$color-black: #000;
$color-app-button-primary-hover: #5e6072;
$color-lilac: #eee9ff;

$color-yellow: #deff4d;

$color-light-red: #ffcfd4;
$color-red: #ff4d5f;

$transparent-light-purple: rgba(199, 185, 255, 0.2);

$card-shadow-color: rgba(222, 255, 77, 0.6);
$card-shadow: 0px 0px 40px 0px $card-shadow-color;

$card-bg: $color-white;
