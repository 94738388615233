@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.rankings-container {
	display: flex;
	float: right;
	color: $color-black;
	font-weight: 700;
	z-index: 10;
	font-size: 16px;
	align-items: center;
	margin-left: 0.7rem;
}

.local-ranking {
	@include labelHighlighted();
}

.global-ranking {
	color: $color-black;
	margin-left: 1.6rem;
	background: $color-yellow;
	padding: 10px;
}
