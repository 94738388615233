@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

html,
body,
#root,
.App {
	margin: 0 !important;
	height: 100%;
	font-family: Slalom Sans !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1,
h2,
h3 {
	font-family: Slalom Sans;
}

a {
	color: $color-black;
	font-weight: bold;
}

a:hover {
	text-decoration: underline;
	color: #00a4f9;
}

body ::-webkit-scrollbar {
	height: 5px;
}

body ::-webkit-scrollbar-thumb {
	background: #ffffff;
	width: 160px;
}

p {
	font-size: 16px;
}

.bug-banner {
	background-color: #dd7d25;
	height: 114px;
	margin: 0 80px;
	font-size: 16px;
}

.title-text {
	font-weight: bold;
	display: flex;
	align-items: center;
}

.title-text-large {
	font-size: 32px;
	line-height: 38px;
}

.title-text-mobile {
	font-size: 18px;
	line-height: 22px;
}

.page-large {
	background-size: 100% 630px;
}

.page-mobile {
	background-size: 100% 370px;
}

.page-title {
	position: absolute;
	right: 0;
	float: right;
	margin-top: 2em;
	width: 15em;
	color: white;
	font-size: 60px;
	font-weight: bold;
	letter-spacing: 0.3em;
	line-height: 90px;
}

.section-container {
	max-width: 1320px;
	margin: auto;
}

@media screen and (max-width: 1183px) {
	.section-container {
		width: 100%;
	}
}

.section-title {
	@include sectionHeader();
}

@media screen and (max-width: 1183px) {
	.section-title {
		margin: 32px 0px;
		font-size: 18px;
		line-height: 21.6px;
	}
}

.app-button-row {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
}

@media screen and (max-width: 705px) {
	.app-button-row {
		flex-direction: row;
	}
}

.app-button-container {
	width: 100%;
}

.app-button {
	font-family: 'Slalom Sans';
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	padding: 1rem 1rem;
	text-align: center;
	border: none;
	width: 100%;
}

.app-button:focus {
	outline: none;
}

.app-button-primary {
	background: $transparent-light-purple;
	border-radius: 50px;
	border: 2px solid $color-light-purple;
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 600;
	font-size: 100%;
	text-align: center;
	line-height: 20px;

	&:disabled {
		background: none;
		border: 2px solid $color-gray;
		color: #b4b4b4;
		cursor: not-allowed;

		&:hover {
			background: none;
			color: $color-white;
			cursor: not-allowed;
		}
	}
}

.app-button-primary:hover {
	text-decoration: none;
	background-color: $color-white;
}

.app-button-secondary {
	background: none;
	color: $color-black;
	padding: 14px 20px;
	gap: 10px;
	text-decoration: none;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
	/* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.app-button-secondary:visited {
	text-decoration: none;
}

.app-button-secondary:hover {
	text-decoration: none;
	color: $color-gray;
}

.app-button-secondary:active {
	color: #ffffff;
}

.app-button-secondary:disabled {
	background: $color-gray;
	color: $color-gray;
}

.disabled {
	background: #b3b5bd;
	color: black;
}

.app-card-display {
	border-radius: 20px;
	background: $card-bg;
	backdrop-filter: blur(40px);
	-webkit-backdrop-filter: blur(40px);
}

.flex-container {
	width: 100%;
	display: flex;
}

.flexible {
	flex-grow: 1;
}

.label-highlighted {
	@include labelHighlighted();
}
