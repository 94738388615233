@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.app-card {
	width: 100%;
	@include fontStandard();
	margin-bottom: 16px;
	display: flex;
	flex-direction: column;
	color: $color-black;
}

.app-card-title {
	font-family: Lora;
	font-size: 32px;
	text-transform: none;
	color: $color-black;
}

.app-card-content {
	@include card();
	display: flex;
	background-color: $color-white;
	flex-direction: column;
	position: relative;
	justify-content: flex-start;
	flex-grow: 1;
	width: 100%;
	padding: 32px;
	border-radius: 16px;
	border: 1px solid $transparent-light-purple;
	background: #FFFFFF;
	border: 2px solid #000000;
	box-shadow: 8px 8px 0px #C7B9FF;
	border-radius: 16px;
}

.app-card-header {
	@include sectionHeader();
}

@media screen and (max-width: 1182px) {
	.app-card-title {
		font-size: 18px;
	}
}
