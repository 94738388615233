@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans_Regular.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans_Black.otf');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans_BlackItalic.otf');
	font-weight: normal;
	font-style: normal;
	color: black;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans_Italic.otf');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans_BoldItalic.otf');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans_Bold.otf');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans_Light.otf');
	font-weight: lighter;
	font-style: normal;
}

@font-face {
	font-family: Slalom Sans;
	src: url('./assets/fonts/SlalomSans/SlalomSans_LightItalic.otf');
	font-weight: lighter;
	font-style: italic;
}

.standalone-page-wrapper {
	margin-top: -80px;
}
