@import "src/assets/sass/variables.scss";

.profile-img {
	margin: auto 0;
	border-radius: 50%;
	padding: 16px;
	width: 92px;
	height: 92px;
}

.contact-info {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: $color-black;
	width: 100%;
}

.contact-name {
	display: flex;
	flex-direction: column;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
}

.contact-market {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: $color-black;
}

.contact-email {
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
	text-decoration-line: underline;
	color: #66A3FF;
}

.profile-card-container {
	min-width: 250px;
	background: $color-black;
	border-radius: 20px;
	display: flex;
	margin: 0 16px 0 0;
}