@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.faq-tile-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 32px;
	justify-content: space-between;
	box-sizing: border-box;
	border-color: $color-black;
	backdrop-filter: blur(40px);
}

.faq-tile-title {
	@include cardTitle();
	font-size: 18px;
	flex: 1;
}

.faq-header > p {
	font-size: 18px;
}

.faq-tile-title-container {
	align-items: center;
	display: flex;
	font-family: 'Lora';
	justify-content: space-between;
	font-style: normal;
	font-weight: 700;
	font-size: 18px;
	line-height: 22px;
	border-radius: 16px;
}

.faq-tile-body-container {
	color: $color-black;
	font-family: Lora;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 19px;
	margin-top: 32px;
	white-space: pre-wrap;
}

.faq-tile-arrow-icon {
	margin-left: 5px;
}
