.splash-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-family: SlalomSans;
	color: white;
}

.splash-background-image {
	z-index: -1;
	object-fit: cover;
	width: 100vw;
	height: calc(100vh + 24px);
	position: fixed;
	top: -12px;
	left: 0;

	&__desktop {
		z-index: -1;
		object-fit: cover;
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		left: 0;
	}
}

.splash-content {
	padding-top: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	background-size: cover;
	// background-image: url('../../assets/images/splash-bg-desktop.jpg');
}
