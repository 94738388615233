@import './variables';

@mixin cardSecondary {
	@include card();
	border: 2px solid $color-black;
	box-shadow: $card-shadow;
}

@mixin fontBase {
	color: $color-black;
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	line-height: 20px;
}

@mixin fontStandard {
	@include fontBase();
	font-family: 'Slalom Sans';
}

@mixin fontSecondary {
	@include fontBase();
	font-family: 'Lora';
}

@mixin card {
	background: $card-bg;

	p {
		@include fontStandard();
		color: $color-black;
	}
}

@mixin cardTitle {
	@include fontSecondary();
	font-weight: 700;
	font-size: 24px;
	line-height: 31px;

	@media screen and (max-width: 1182px) {
		font-size: 18px;
		line-height: 23px;
	}
}

@mixin leaderboardCard {
	background: $color-yellow;
	color: $color-yellow;
}

@mixin fontHeader {
	@include fontStandard();
	font-family: 'Lora';
	font-size: 28px;
	font-weight: 700;
	line-height: 36px;
}

@mixin headerFooter {
	background: rgba(238, 233, 255, 1);
	backdrop-filter: blur(20px);
}

@mixin sectionHeader {
	@include fontHeader();
	font-family: 'Lora';
	margin: 32px 0px;
	display: flex;
	align-items: center;
}

@mixin labelHighlighted {
	background-color: $color-yellow;
	padding: 10px;
	color: $color-black;
	font-weight: 700;
	line-height: 19px;
}
