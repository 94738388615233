@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.about-link:hover {
	color: white;
}

.about-container {
	max-width: 1320px;
	margin: auto;
}

.about-page-text-container-desktop {
	background: $color-bg-light;
	backdrop-filter: blur(40px);
	/* Note: backdrop-filter has minimal browser support */
	border-radius: 20px;
	box-sizing: border-box;
	padding: 32px;
	flex-direction: column;
	min-height: 300px;
}

.about-page-text-container-tablet,
.about-page-text-container-mobile {
	background: $color-bg-light;
	backdrop-filter: blur(40px);
	border-radius: 20px;
	box-sizing: border-box;
	padding: 32px;
	flex-direction: column;
}

.about-page-text-body-container {
	font-family: Lora;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 20px;
	margin: 0;
	color: $color-black;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.about-header {
	@include sectionHeader();
}

.about-header-tablet,
.about-header-mobile {
	font-size: 18px;
	line-height: 22px;
	margin-top: 48px;
	margin-bottom: 32px;
}

.about-title {
	font-family: Lora;
	font-style: normal;
	font-weight: 700;
	text-align: left;
	margin-top: 32px;
	margin-bottom: 32px;
	text-transform: none;
	color: rgba(0, 0, 0, 0.8);
	font-size: 32px;
}

.about-title-large {
	font-size: 32px;
	line-height: 38px;
}

.about-title-mobile {
	font-size: 22px;
	line-height: 26px;
}

.about-welcome-desktop {
	display: flex;
	width: 100%;
}

.about-welcome-desktop > section {
	width: 100%;
}

.about-welcome-tablet,
.about-welcome-mobile {
	display: flex;
	flex-direction: column;
}

.welcome-section-desktop {
	margin-right: 1.5rem;
}

.welcome-section-tablet,
.welcome-section-mobile {
	margin-right: 0;
}

.welcome-section-mobile:first-child,
.welcome-section-tablet:first-child {
	margin-bottom: 32px;
}

.about-section-title {
	@include cardTitle();
	padding-bottom: 16px;
	gap: 10px;
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	white-space: pre-line;
}

.contact-section-container-desktop {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	gap: 20px;
}

.contact-section-container-mobile,
.contact-section-container-tablet {
	display: flex;
	flex-direction: row;
	align-items: space-between;
	gap: 20px;
	overflow-x: auto;
}

.contact-section-title {
	@include cardTitle();
	padding-bottom: 16px;
	gap: 10px;
	display: flex;
	align-items: center;
	margin-bottom: 15px;
}

.contact-card-desktop {
	flex: 1;
}

.contact-card-tablet {
	min-height: 319px;
	flex-shrink: 0.2;
}

.contact-card-mobile {
	min-height: 319px;
	flex-shrink: 0.1;
}

.about-page-btn-container {
	display: flex;
	flex-direction: column;
	align-self: center;
	text-align: center;
}

.about-page-card-footer-desktop {
	display: flex;
	justify-content: center;
	margin-top: 36px;
}

.about-page-card-footer-tablet {
	display: flex;
	justify-content: center;
	margin-top: auto;
}

.about-page-card-footer-mobile {
	display: flex;
	justify-content: center;
	margin-top: 36px;
}
