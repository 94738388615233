@import 'src/assets/sass/variables.scss';
@import 'src/assets/sass/mixins';

.quiz-description {
	.app-card-content {
		@include card();
		display: flex;
		flex-direction: row;
		color: $color-black;

		@media screen and (max-width: 705px) {
			flex-direction: column;
		}
	}
}

.quiz-description-container {
	background-color: $color-bg-light;
	display: flex;
	align-items: center;
	border-radius: 16px;
	font-family: SlalomSans;
	color: $color-white;
}

.quiz-description-container.desktop {
	background: none;
	flex-direction: row;
}

.quiz-description-container.tablet {
	background: none;
	flex-direction: column;
}

.quiz-description-container.mobile {
	background: none;
	flex-direction: column;
}

.quiz-description-container p {
	font-size: 16px;
	padding-right: 32px;
}

.quiz-content {
	.app-card-content {
		@include card();
	}

	&__title {
		@include cardTitle();
		font-size: 20px;
		margin-bottom: 16px;
		line-height: 26px;
	}
}

.quiz-content-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	border-radius: 16px;
	font-family: SlalomSans;
	color: $color-white;
}

.points-container {
	white-space: nowrap;
	margin-right: 48px;
	text-align: center;
	font-size: 16px;
	font-weight: 700;
	color: $color-yellow;
}

.points-container > * {
	margin: 5px;
	vertical-align: middle;
	display: inline-block;
}

.quiz-question {
	font-size: 16px;
	line-height: 20px;
}

.quiz-icon-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.quiz-answers-container {
	margin-top: 40px;
}

.quiz-answers-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;

	@media screen and (max-width: 968px) {
		justify-content: space-between;
	}
}

.quiz-button {
	@include fontStandard();
	display: flex;
	flex-direction: row;
	align-items: center;
	position: relative;
	border-radius: 50px;
	margin: 16px 16px 16px 0;
	padding: 1rem;
	background-color: $transparent-light-purple;
	border: 2px solid $color-light-purple;
	max-width: 300px;
	flex-grow: 1;
	cursor: pointer;

	@media screen and (max-width: 968px) {
		width: 48%;
		max-width: 48%;
		margin: 16px 0;
	}

	@media screen and (max-width: 480px) {
		width: 100%;
		max-width: 100%;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		box-shadow: 0px 1px 10px 0px $color-light-purple;

		.quiz-button-circle {
			background-color: $transparent-light-purple;
		}
	}

	&-circle {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
		border: 2px solid $color-purple;
		height: 25px;
		width: 25px;
		min-width: 25px;
		border-radius: 50%;
		&--inner {
			width: 15px;
			height: 15px;
			background-color: transparent;
			border-radius: 50%;
		}
	}

	&-answer {
		flex-grow: 1;
		text-align: left;
	}

	&-selected {
		font-weight: 700;
		.quiz-button {
			&-circle {
				border-width: 3px;
				&--inner {
					background-color: $color-purple;
				}
			}
		}
	}

	&-correct {
		box-shadow: none;
		border-color: $color-light-purple;
		cursor: initial;
		color: black;
		font-weight: 700;

		&:hover {
			box-shadow: none;
		}

		.quiz-button-circle {
			border: 3px solid $color-purple;
			background-color: transparent;

			&--inner {
				background-color: $color-purple;
			}
		}
	}

	&-wrong {
		border-color: $color-red;
		background-color: $color-light-red;
		box-shadow: none;
		cursor: initial;
		color: black;
		font-weight: 700;

		&:hover {
			box-shadow: none;
		}

		.quiz-button-circle {
			border: 3px solid $color-red;
			background-color: transparent;

			&--inner {
				background-color: $color-red;
			}
		}
	}
}

.quiz-button.desktop {
	background: none;
	width: 23%;
}

.quiz-button.tablet {
	background: none;
	width: 48%;
}

.quiz-button.mobile {
	background: none;
	width: 98%;
}

.quiz-button-border.selected {
	position: absolute;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	border-radius: 50px;
	z-index: -1;
}

.quiz-button-description {
	display: inline-block;
	padding: 10px 75px 10px 25px;
}

.quiz-submission {
	&-container {
		margin: 16px 0;
		display: flex;
		flex-direction: row;
		justify-content: center;
		justify-items: center;

		&.desktop {
			background: none;
			justify-content: flex-end;
		}

		&.tablet {
			justify-items: center;
			background: none;
			flex-direction: column;
		}

		&.mobile {
			background: none;
			flex-direction: column;
		}
	}

	&-content {
		color: $color-gray;
		display: flex;
		flex-direction: column;
		align-items: center;

		.app-button-generic-container {
			margin-bottom: 8px;
			width: 95px;
		}

		p {
			font-size: 14px !important;
		}

		@media screen and (min-width: 969px) {
			max-width: 100px;
			p {
				text-align: center;
			}
		}
	}
}

.correct-explanation {
	font-size: bold;
	color: white;
}

.wrong-explanation {
	font-size: bold;
	color: white;
	margin-bottom: 16px;
}
