@import 'src/assets/sass/variables.scss';

.gallery-tile-title {
    padding-bottom: 16px;
    font-family: Lora;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: $color-black;
}

.gallery-tile-content {
    padding-bottom: 16px;
    font-family: Lora;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: $color-black;
}
