@import "src/assets/sass/variables.scss";
@import 'src/assets/sass/mixins';

@font-face {
	font-family: Lora;
	src: url("../../assets/fonts/SlalomSans/SlalomSans_Regular.otf");
}

.team-profile-tile-container {
	flex-basis: 23.5%;
	min-width: 250px;
	max-width: 250px;
	height: 323px;
	justify-content: center;
	display: flex;
	font-family: Lora;
	border-radius: 20px;
}

.team-profile-front-tile-image-container {
	color: FFFFFF;
	width: 100%;
	height: 241px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	border: 2px solid $color-black;
	border-width: 2px 2px 0 2px; 
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	box-shadow: 8px 8px 0px $color-light-purple;
}

.team-profile-front-tile-info-container {
	width: 100%;
	padding: 0 8px;
	height: 100px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	background: linear-gradient(0deg, #EEE9FF, #EEE9FF);
	backdrop-filter: blur(21px);
	border: 2px solid $color-black;
	border-width: 0 2px 2px 2px; 
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	box-shadow: 8px 8px 0px $color-light-purple;
}

.team-profile-name-container {
	font-family: "Lora";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	color: $color-black;
}

.team-profile-role-container {
	font-size: 14px;
	line-height: 20px;
	font-weight: 400, regular;
	color: $color-black;
}

.about-info-container {
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
}

.team-profile-back-tile-container {
	width: 250px;
	height: 80px;
	font-weight: 400, regular;
	font-size: 16px;
	line-height: 19px;
	margin-top: 140px;
	position: absolute;
	text-align: center;
	justify-content: center;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.team-profile-quote-container {
	margin-top: 20px;
	margin-left: 5px;
}

@media screen and (max-width: 1182px) {
	.profile-tile-margin {
		margin-right: 16px;
	}
}