@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.event-card-container {
	font-family: Lora;
	flex-direction: column;
	justify-content: space-between;
	border-width: 2px;
	border-style: solid;
	border-radius: 20px;
	border-color: $transparent-light-purple;
}

.event-card-container-mobile {
	margin: 24px 0 0 0px;
}

@media screen and (max-width: 425px) {
	.event-card-container-mobile {
		width: 100%;
	}
}

.card-back {
	background: $card-bg;
	border: $color-black;
	border-radius: 20px;
}

/* Needed to override semantic-ui styles */
.ui.active.modal {
	display: flex !important;
	justify-content: center;
	box-shadow: none;
	max-width: 686px;
}

.ui.modal {
	background: none;
	border-radius: 10px;
}

.ui.modal > .icon:first-child + *,
.ui.modal > :first-child:not(.icon) {
	border-radius: 10px;
}

.ui.basic.modal > .close {
	top: 3.5rem;
	right: 6rem;
}

.ui.modal > .close {
	cursor: pointer;
	position: absolute;
	z-index: 1;
	opacity: 0.8;
	font-size: 2em;
	color: #fff;
	width: 2.25rem;
	height: 2.25rem;
	padding: 0.625rem 0 0 0;
}

.modals.dimmer .ui.scrolling.modal .visible.transition {
	margin: 1rem auto;
	display: flex;
	justify-content: center;
}

.event-weekday {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: $color-black;
}

.event-date {
	@include fontStandard();
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	margin-top: 16px;
	margin-bottom: 30px;
}

.event-time {
	font-size: 16px;
	font-weight: 300;
	line-height: 20px;
	color: $color-black;
}

.event-details {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	color: $color-black;
}

.event-details-expanded {
	height: 55%;
	overflow-y: auto;
	padding-right: 32px;
	margin-bottom: 30px;
	scrollbar-color: white transparent;
}

.event-card-title {
	font-family: Lora;
	font-size: 25px;
	font-weight: 900;
	margin-bottom: 36px;
	line-height: 28px;
	color: $color-black;
}

.event-detail-title {
	font-size: 25px;
	font-weight: 900;
	line-height: 32px;
	max-width: 350px;
}

.event-points-container {
	display: flex;
	font-family: Slalom Sans;
	width: 165px;
	align-items: center;
}

.event-points {
	@include labelHighlighted();
}

.event-content {
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	margin-bottom: 30px;
	width: 100%;
	word-wrap: break-word;
	color: $color-white;
}

.event-location {
	font-size: 16px;
	color: $color-black;
}

.event-card-btn-container-mobile {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	height: 15%;
}

.event-btn {
	height: 48px;
	border-radius: 8px;
	box-sizing: border-box;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 19px;
	width: 100%;
	margin-bottom: 16px;
	background: $card-bg;
}

.add-to-calendar-btn {
	display: block;
	justify-content: center;
	width: 100%;
	margin-bottom: 16px;
	width: 164px;
	height: 47px;
}

.event-card-primary-btn-wrapper {
	display: flex;
	justify-content: center;
}

.see-details-btn {
	background: #244354;
	border: 3px solid #00d9ef;
	color: #1be1f2;
	box-shadow: 0px 0px 10px 1px #00d9ef;
}

.add-to-cal-btn {
	background: $color-slalom-primary;
	border: 3px solid white;
	color: white;
	box-shadow: 0px 0px 10px 1px $color-slalom-primary;
}

.event-speakers {
	font-size: 16px;
	font-weight: bold;
	line-height: 20px;
}

.event-speaker {
	text-align: center;
	font-weight: 400;
	background: $color-dark-purple;
}

.event-speaker-pic {
	border-radius: 50%;
	margin-bottom: 10px;
	border: 3px solid #00a4f9;
}

.event-speaker-container {
	display: flex;
	justify-content: space-around;
	padding: 15px 0 30px;
	background: $transparent-light-purple;
	border: 1px solid $color-light-purple;
	border-radius: 20px;
}

.event-card-points-icon {
	width: 40px;
	height: 40px;
	background-color: $color-white;
}

@media screen and (min-width: 1183px) {
	.event-card-container {
		width: 32%;
	}
}

@media screen and (max-width: 1182px) {
	.event-card-container {
		width: 350px;
		min-width: 350px;
		margin: 24px 16px 24px 0px;
	}
}

.btn-content-color {
	color: $color-black;
}

.btn-content-color:hover {
	color: $color-light-purple;
	transition: 0.7s;
}

.learn-more-btn {
	display: block;
	justify-content: center;
	margin-bottom: 16px;
	width: 164px;
	height: 47px;
}
