@import 'src/assets/sass/variables';
@import 'src/assets/sass/mixins';

.home-page-desktop,
.home-page-tablet {
	background-size: 100% 630px;
}

.home-page-mobile {
	background-size: 100% 370px;
}

.home-virtual-gallery-container{
	max-width: 1320px;
	margin: auto;
}
