@import 'src/assets/sass/variables.scss';

.tooltip-container {
	position: relative;
	display: flex;
	height: auto;
	align-items: center;
	margin: 32px 16px;
}

.tooltip-icon {
	cursor: pointer;
	border-radius: 20px;
	background: #EEE9FF;
}

.tooltip-content {
	position: absolute;
	width: 280px;
	block-size: fit-content;
	right: 40px;
	top: -16px;
	z-index: 10;
	padding-bottom: 16px;
	padding-left: 16px;
	padding-right: 16px;


	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 19px;
	color: #000;
	background: rgba(238, 233, 255, 1);
	border-radius: 20px;
	border: 1px solid $color-purple;
}

.tooltip-close {
	text-align: right;
	cursor: pointer;
	padding-right: 16px;
	padding-top: 16px;
}
