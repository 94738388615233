@import "src/assets/sass/variables.scss";
@import 'src/assets/sass/mixins';

input::placeholder {
	font-family: 'Slalom Sans';
	font-weight: 400;
	font-size: 16px;
	color: $color-black;
	opacity: 1;
	letter-spacing: 0;
}

.claim-points-bar {
	// @include cardSecondary();
	display: flex;
	width: 100%;
	padding: 24px;
	align-items: center;
	background: $color-white;
	backdrop-filter: blur(40px);
	border-radius: 16px;
	border: 2px solid $color-black;
	font-family: Lora;
	color: $color-black;
	box-shadow: 8px 8px 0px $color-light-purple;
}

.claim-points-bar-desktop {
	flex-direction: row;
	justify-content: space-between;
	align-items: start;
	padding-bottom: 0;
}

.claim-points-bar-tablet {
	flex-direction: column;
}

.claim-points-bar-mobile {
	flex-flow: row wrap;
}

.claim-points-description-container {
	display: flex;
	flex-direction: column;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
}


.claim-points-header {
	font-weight: 700;
	font-size: 16px;
	height: 20px;
	color: $color-black;
	margin-top: 8px;
}

.claim-points-header-secondary {
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 19px;
}

.claim-points-body-desktop {
	font-family: 'Slalom Sans';
	font-style: normal;
	margin-top: 16px;
	margin-bottom: 30px;
}

.claim-points-body-tablet,
.claim-points-body-mobile {
	font-family: 'Slalom Sans';
	font-style: normal;
	margin-top: 32px;
}

.claim-points-description-container p {
	margin-top: 16px;
	padding-top: 16px;
	padding-bottom: 24px;
	line-height: 18px;
}

.description-container-desktop {
	min-width: 300px;
	max-width: 350px;
}

.description-container-tablet,
.description-container-mobile {
	width: 100%;
}

.claim-points-form-inline {
	display: flex;
	align-items: center;
}

.claim-points-form-inline-desktop {
	flex-direction: row;
	margin-left: 48px;
	width: 100%;
}

.claim-points-form-inline-tablet,
.claim-points-form-inline-mobile {
	flex-flow: row wrap;
	width: 100%;
	margin-top: 32px;
}

.claim-points-enter-code-container-desktop {
	display: flex;
	flex-direction: column;
	color: black;
	font-size: 16px;
	width: 575px;
}

.claim-points-enter-code-container-tablet,
.claim-points-enter-code-container-mobile{
	display: flex;
	flex-direction: column;
	color: black;
	font-size: 16px;
	width: 100%;
}

.claim-points-input {
	box-sizing: border-box;
	margin-top: 18px;
	width: 100%;
	height: 51px;
	font-size: 20px;
	padding-left: 25px;
	text-align: left;
	letter-spacing: 32px;
	background: $color-white;
	border: 2px solid $color-light-purple;
	color: $color-black;
	border-radius: 50px;
}

.claim-points-input:focus {
	outline: none;
}

.claim-points-input-error {
	background: #FFCCC0;
	border-color: transparent;
	color: $color-black;
}

.claim-points-input-success {
	background: #C3E4BE;
	border-color: transparent;
	color: $color-black;
}

#claim-points-input-message {
	height: 24px;
	color: $color-black;
	margin-top: 12px;
}

.claim-points-submit-container-desktop {
	width: 300px;
	margin-top: 46px;
}

.claim-points-submit-container-tablet,
.claim-points-submit-container-mobile {
	text-align: center;
	width: 300px;
	margin: auto;
}

.claim-points-button-message-desktop {
	margin-left: 32px;
	margin-top: 12px;
	margin-bottom: 12px;
	height: 20px;
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
}

.claim-points-button-message-tablet,
.claim-points-button-message-mobile {
	margin-top: 12px;
	text-align: center;
	font-family: 'Slalom Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 19px;
}

.claim-points-button-container-desktop {
	display: flex;
	flex-direction: column;
	color: black;
	width: 100px;
	height: 50px;
	margin-left: 32px;
}

.claim-points-button-container-tablet,
.claim-points-button-container-mobile {
	width: 95px;
	margin: auto;
}

#claim-points-button-incomplete {
	background-color: #d1d1d1;
	border: 0px;
}

#claim-points-button-complete {
	background-color: $color-slalom-primary;
	border: 2px solid #ffffff;
	box-sizing: border-box;
	box-shadow: 0px 0px 10px 1px #008bff;
	border-radius: 8px;
}

@media screen and (max-width: 1182px) {
	.claim-points-button-container {
		width: 100%;
		margin: 0;
	}
}